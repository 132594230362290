import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { ERouterName } from '/@/types/index'
import CreatePlan from '/@/components/task/CreatePlan.vue'
import WaylinePanel from '/@/pages/page-web/projects/wayline.vue'
import DockPanel from '/@/pages/page-web/projects/dock.vue'
// import LiveAgora from '/@/components/livestream-agora.vue'
import LiveOthers from '/@/components/livestream-others.vue'
import CreateWayline from '/@/components/wayline/CreateWayline.vue'
import CreateModel from '/@/components/model/CreateModel.vue'
import selectModel from '/@/components/model/selectModel.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/' + ERouterName.LOGIN
  },
  // 首页
  {
    path: '/' + ERouterName.LOGIN,
    name: ERouterName.LOGIN,
    component: () => import('/@/pages/page-web/index.vue')
  },
  // 项目列表
  {
    path: '/' + ERouterName.PROJECT,
    name: ERouterName.PROJECT,
    component: () => import('/@/pages/page-web/projects/project.vue')
  },
  // 直播分享
  {
    path: '/share',
    name: 'share',
    component: () => import('/@/components/share.vue')
  },
  // 编辑航线 航点飞行
  // {
  //   path: '/' + ERouterName.EDIT_WAYLINE,
  //   name: ERouterName.EDIT_WAYLINE,
  //   component: () => import('/@/components/wayline/EditWayline.vue')
  // },
  // 编辑航线2d 建图航拍
  // {
  //   path: '/' + ERouterName.EDIT_WAYLINE2D,
  //   name: ERouterName.EDIT_WAYLINE2D,
  //   component: () => import('/@/components/wayline/EditWayline2d.vue')
  // },
  // 编辑环绕航线
  // {
  //   path: '/' + ERouterName.EDIT_WAYLINECIRCLE,
  //   name: ERouterName.EDIT_WAYLINECIRCLE,
  //   component: () => import('/@/components/wayline/EditWaylineCircle.vue')
  // },
  // 编辑航线3d 倾斜摄影
  {
    path: '/' + ERouterName.EDIT_WAYLINE3D,
    name: ERouterName.EDIT_WAYLINE3D,
    component: () => import('/@/components/wayline/EditWayline3d.vue')
  },
  // members, devices
  {
    path: '/' + ERouterName.HOME,
    name: ERouterName.HOME,
    component: () => import('/@/pages/page-web/home.vue'),
    children: [
      {
        path: '/' + ERouterName.MEMBERS,
        name: ERouterName.MEMBERS,
        component: () => import('/@/pages/page-web/projects/members.vue')
      },
      {
        path: '/' + ERouterName.DEVICES,
        name: ERouterName.DEVICES,
        component: () => import('/@/pages/page-web/projects/devices.vue')
      },
      {
        path: '/' + ERouterName.FIRMWARES,
        name: ERouterName.FIRMWARES,
        component: () => import('../pages/page-web/projects/Firmwares.vue')
      },
      {
        path: '/' + ERouterName.ALGORITHMS,
        name: ERouterName.ALGORITHMS,
        component: () => import('../pages/page-web/projects/algorithms.vue')
      },
      {
        path: '/' + ERouterName.WORKSPACE,
        name: ERouterName.WORKSPACE,
        component: () => import('../pages/page-web/projects/organization.vue')
      },
    ]
  },
  // workspace
  {
    path: '/' + ERouterName.TSA,
    name: ERouterName.TSA,
    component: () => import('/@/pages/page-web/projects/workspace.vue'),
    // redirect: '/' + ERouterName.TSA,
    children: [
      {
        path: '/' + ERouterName.TSA,
        name: ERouterName.TSA_SIDER,
        component: () => import('/@/pages/page-web/projects/tsa.vue')
      },
      {
        path: '/' + ERouterName.LIVESTREAM,
        name: ERouterName.LIVESTREAM,
        component: () => import('/@/pages/page-web/projects/livestream.vue'),
        children: [
          {
            path: ERouterName.LIVING,
            name: ERouterName.LIVING,
            components: {
              // LiveAgora,
              LiveOthers
            }
          }
        ]
      },
      // {
      //   path: '/' + ERouterName.LIVE,
      //   name: ERouterName.LIVE,
      //   component: () => import('/@/pages/page-web/projects/livertc.vue'),
      // },
      {
        path: '/' + ERouterName.LAYER,
        name: ERouterName.LAYER,
        component: () => import('/@/pages/page-web/projects/layer.vue')
      },
      {
        path: '/' + ERouterName.MEDIA,
        name: ERouterName.MEDIA,
        component: () => import('/@/pages/page-web/projects/media.vue')
      },
      {
        path: '/' + ERouterName.AI,
        name: ERouterName.AI,
        component: () => import('/@/pages/page-web/projects/ai.vue')
      },
      {
        path: '/' + ERouterName.INSPECT,
        name: ERouterName.INSPECT,
        component: () => import('/@/pages/page-web/projects/inspect.vue')
      },
      {
        path: '/' + ERouterName.RECORD,
        name: ERouterName.RECORD,
        component: () => import('/@/pages/page-web/projects/record.vue')
      },
      {
        path: '/' + ERouterName.WAYLINE,
        name: ERouterName.WAYLINE,
        component: () => import('/@/pages/page-web/projects/wayline.vue'),
        children: [
          {
            path: ERouterName.CREATE_WAYLINE,
            name: ERouterName.CREATE_WAYLINE,
            component: CreateWayline,
            children: [
              {
                path: ERouterName.CREATE_WAYLINE,
                name: ERouterName.WAYLINE_SIDER,
                components: {
                  WaylinePanel,
                  DockPanel
                }
              }
            ]
          },
          {
            path: ERouterName.EDIT_WAYLINE,
            name: ERouterName.EDIT_WAYLINE,
            component: () => import('/@/components/wayline/EditWayline.vue'),
          },
          {
            path: ERouterName.EDIT_WAYLINE2D,
            name: ERouterName.EDIT_WAYLINE2D,
            component: () => import('/@/components/wayline/EditWayline2d.vue'),
          },
          {
            path: ERouterName.EDIT_WAYLINECIRCLE,
            name: ERouterName.EDIT_WAYLINECIRCLE,
            component: () => import('/@/components/wayline/EditWaylineCircle.vue'),
          },
        ]
      },
      {
        path: '/' + ERouterName.TASK,
        name: ERouterName.TASK,
        component: () => import('/@/pages/page-web/projects/task.vue'),
        children: [
          {
            path: ERouterName.CREATE_PLAN,
            name: ERouterName.CREATE_PLAN,
            component: CreatePlan,
            children: [
              {
                path: ERouterName.SELECT_PLAN,
                name: ERouterName.SELECT_PLAN,
                components: {
                  WaylinePanel,
                  DockPanel
                }
              }
            ]
          }

        ]
      },
      {
        path: '/' + ERouterName.FLIGHT_AREA,
        name: ERouterName.FLIGHT_AREA,
        component: () => import('/@/pages/page-web/projects/flight-area.vue')
      },
      {
        path: '/' + ERouterName.MODEL,
        name: ERouterName.MODEL,
        component: () => import('/@/pages/page-web/projects/model.vue'),
        children: [
          {
            path: ERouterName.CREATE_MODEL,
            name: ERouterName.CREATE_MODEL,
            component: CreateModel,
            children: [
              {
                path: ERouterName.SELECT_MODEL,
                name: ERouterName.SELECT_MODEL,
                components: {
                  selectModel
                }
              }
            ]
          }
        ]
      },
    ]
  },
  // pilot
  {
    path: '/' + ERouterName.PILOT,
    name: ERouterName.PILOT,
    component: () => import('/@/pages/page-pilot/pilot-index.vue'),
  },
  {
    path: '/' + ERouterName.PILOT_HOME,
    component: () => import('/@/pages/page-pilot/pilot-home.vue')
  },
  {
    path: '/' + ERouterName.PILOT_MEDIA,
    component: () => import('/@/pages/page-pilot/pilot-media.vue')
  },
  {
    path: '/' + ERouterName.PILOT_LIVESHARE,
    component: () => import('/@/pages/page-pilot/pilot-liveshare.vue')
  },
  {
    path: '/' + ERouterName.PILOT_BIND,
    component: () => import('/@/pages/page-pilot/pilot-bind.vue')
  },
  {
    path: '/' + ERouterName.MODEL_IMPORT,
    name: ERouterName.MODEL_IMPORT,
    component: () => import('/@/components/model/modelImport.vue')
  },
  {
    path: '/' + ERouterName.LIVE,
    name: ERouterName.LIVE,
    component: () => import('/@/pages/page-web/projects/live.vue')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router
