<template>
  <div class="create-plan-wrapper">
    <div class="header">
      模型重建
    </div>
    <div class="content">
      <a-form ref="valueRef" layout="vertical" :hideRequiredMark="true" :rules="rules" :model="modelBody" labelAlign="left">
        <a-form-item label="模型名称" name="jobName" :labelCol="{span: 8}">
          <a-input style="background: black;" :maxlength="30" placeholder="请输入模型名称" v-model:value="modelBody.jobName"/>
        </a-form-item>
        <div class="content-img">
          <div>重建照片(广角)</div>
          <div class="image">
            <div class="image-count">照片数量: {{ folderData.dataChild.length }}</div>
            <div class="select-image">
              <router-link :to="{name: 'select-model'}" @click="selectRoute">选择照片</router-link>
            </div>
          </div>
        </div>
        <div class="content-folder" v-for="item in checkedKeysData.data" :key="item.key">
          <a-image :src="folderImg" :preview="false" style="width: 24px;height: 24px;margin-right: 5px;"></a-image>
          <div class="content-folder-title">{{ item.title}}</div>
          <AimOutlined style="color: green;margin-left: auto;cursor: pointer;font-size: 18px;" @click="locationFolder(item.key)"/>
          <MinusCircleOutlined style="color: red;margin-left: 10px;cursor: pointer;" @click="removeFolder(item.key)"/>
        </div>
        <a-form-item label="重建类型" class="plan-timer-form-item">
          <div style="white-space: nowrap;">
            <a-radio-group v-model:value="modelBody.jobType" button-style="solid" @change="jobTypeChange">
              <a-radio-button style="width: 130px;" :value="0">二维</a-radio-button>
              <a-radio-button style="width: 130px;" :value="1">三维</a-radio-button>
            </a-radio-group>
          </div>
        </a-form-item>
        <a-form-item v-if="modelBody.jobType===1" label="建图场景" :labelCol="{span: 8}">
          <a-select style="background: black;" placeholder="请选择建图场景" v-model:value="modelBody.reconstructionMode">
            <a-select-option :value='1'>普通场景</a-select-option>
            <a-select-option :value='2'>环绕场景</a-select-option>
          </a-select>
        </a-form-item>
        <div class="content-img" style="line-height: 22px;">
          <div>兴趣区域</div>
          <a-switch :checked="enablePolyon" @click="switchPolyonClick" />
        </div>
        <div class="content-img" v-if="modelBody.jobType===1" style="border-top: 2px solid rgba(255, 255, 255, 0.1); margin-left: -10px; margin-right: -10px; padding: 10px 10px 0px; color: rgba(255, 255, 255, 0.45);">
          <div class="image">
            <div class="image-count" style="font-size: 14px;">模型输出</div>
          </div>
        </div>
        <div class="content-img" v-if="modelBody.jobType===1" style="line-height: 22px;">
          <div>Mesh 模型</div>
          <a-switch :checked="enableMesh" :disabled="!enableCloud" @click="switchMeshClick" />
        </div>
        <div class="content-img" v-if="modelBody.jobType===1 && enableMesh">
          <a-button :type="modelBody.generateB3dm?'primary':'default'" size="small" block shape="round"
            :disabled="modelBody.generateB3dm && !modelBody.generateOsgb && !modelBody.generatePly && !modelBody.generateObj"
          @click="modelBody.generateB3dm = !modelBody.generateB3dm">B3DM</a-button>
          <a-button :type="modelBody.generateOsgb?'primary':'default'" size="small" block shape="round"
            :disabled="modelBody.generateOsgb && !modelBody.generateB3dm && !modelBody.generatePly && !modelBody.generateObj"
          @click="modelBody.generateOsgb = !modelBody.generateOsgb">OSGB</a-button>
          <a-button :type="modelBody.generatePly?'primary':'default'" size="small" block shape="round"
            :disabled="modelBody.generatePly && !modelBody.generateB3dm && !modelBody.generateOsgb && !modelBody.generateObj"
          @click="modelBody.generatePly = !modelBody.generatePly">PLY</a-button>
          <a-button :type="modelBody.generateObj?'primary':'default'" size="small" block shape="round"
            :disabled="modelBody.generateObj && !modelBody.generateB3dm && !modelBody.generateOsgb && !modelBody.generatePly"
          @click="modelBody.generateObj = !modelBody.generateObj">OBJ</a-button>
        </div>
        <div class="content-img" v-if="modelBody.jobType===1" style="line-height: 22px;">
          <div>点云模型</div>
          <a-switch :checked="enableCloud" :disabled="!enableMesh" @click="switchCloudClick" />
        </div>
        <div class="content-img" v-if="modelBody.jobType===1 && enableCloud">
          <a-button :type="modelBody.generatePnts?'primary':'default'" size="small" block shape="round"
            :disabled="modelBody.generatePnts && !modelBody.generateLas && !modelBody.generatePointPly"
          @click="modelBody.generatePnts = !modelBody.generatePnts">PNTS</a-button>
          <a-button :type="modelBody.generateLas?'primary':'default'" size="small" block shape="round"
            :disabled="modelBody.generateLas && !modelBody.generatePnts && !modelBody.generatePointPly"
          @click="modelBody.generateLas = !modelBody.generateLas">LAS</a-button>
          <a-button :type="modelBody.generatePointPly?'primary':'default'" size="small" block shape="round"
            :disabled="modelBody.generatePointPly && !modelBody.generatePnts && !modelBody.generateLas"
          @click="modelBody.generatePointPly = !modelBody.generatePointPly">PLY</a-button>
        </div>
        <div class="content-img">
          <div>坐标系选择</div>
          <div class="image">
            <div class="image-count">WGS 84</div>
            <div class="select-image">
              <router-link :to="{name: 'select-model'}">EPSG:32650</router-link>
            </div>
          </div>
        </div>
        <div class="content-img" v-if="modelBody.jobType===1">
          <div>模型简化至</div>
        </div>
        <div class="content-img" v-if="modelBody.jobType===1" style="color: #000;">
          <a-button type="primary" style="background: #3c3c3c;border: none;"  @click="modelBody.modelSimplify -= 0.1"
            :disabled="modelBody.modelSimplify<=0.1">
            <template #icon><MinusOutlined /></template>
          </a-button>
          <a-input-number v-model:value="modelBody.modelSimplify" :min="0.1" :max="1"
            :step="0.1" style="margin: 0;width: 60px;">
          </a-input-number>
          <a-button type="primary" @click="modelBody.modelSimplify += 0.1"
            style="background: #3c3c3c;border: none;" :disabled="modelBody.modelSimplify>=1">
            <template #icon><PlusOutlined /></template>
          </a-button>
        </div>
        <div class="content-img" v-if="modelBody.reconstructionMode===2">
          <div class="image">
            <div class="image-count">数值越低，简化程度越高精细度越低，可使云端浏览更流畅。建议最低为0.2，若电脑性能较强且对模型精细度要求较高可适当调高。</div>
          </div>
        </div>
        <a-form-item class="width-100" style="margin-bottom: 40px;">
          <div class="footer">
            <a-button class="mr10" style="background: #3c3c3c;" @click="closeModel">取消</a-button>
            <a-button type="primary" @click="onSubmit" :disabled="disabled">确定</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
  <div v-if="drawerVisible" style="position: absolute; left: 335px; width: 280px; height: 100vh; float: right; top: 0; z-index: 1000; color: white; background: #282828;">
    <div>
      <router-view :name="routeName"/>
    </div>
    <div style="position: absolute; top: 15px; right: 10px;">
      <a style="color: white;" @click="closePanel"><CloseOutlined /></a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch, reactive, ref, onUnmounted } from 'vue'
import { ELocalStorageKey, ERouterName } from '/@/types'
import { useMyStore } from '/@/store'
import { getRoot } from '/@/root'
import folderImg from '/@/assets/file.png'
import { CloseOutlined, PlusOutlined, MinusOutlined, MinusCircleOutlined, AimOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { createJob, getJobList } from '/@/api/model'
import router from '/@/router'
import { useGMapCover } from '/@/hooks/use-g-map-cover'
import { useMapTool } from '/@/hooks/use-map-tool'
import { CURRENT_CONFIG as config } from '/@/api/http/config'
import { getMediaFiles } from '/@/api/media_v2'

const root = getRoot()
const store = useMyStore()
const useGMapCoverHook = useGMapCover()
const useMapToolHook = useMapTool()
const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!
const disabled = ref(false)
const enableMesh = ref(true)
const enablePolyon = ref(false)
const enableCloud = ref(false)
const drawerVisible = ref(false)
const routeName = ref('')
const valueRef = ref()

const modelBody = reactive({
  jobName: '',
  folderUUIDs: [] as string[],
  jobType: 0,
  isShow: 0,
  reconstructionMode: 1,
  outputPointCloud: false,
  modelSimplify: 0.2,
  wkt: 'EPSG:32650',
  generateB3dm: true,
  generateOsgb: false,
  generatePly: false,
  generateObj: false,
  generatePnts: false,
  generateLas: false,
  generatePointPly: false,
  polygonPoints: [] as any[],
})

const folderData = reactive({
  data: [] as any[],
  dataChild: [] as any[],
})

const rules = {
  jobName: [{ required: true, message: '请输入模型名称.' }],
  type: [{ required: true, message: '请选择飞行器与负载' }],
  payload_model_keys: [{ required: true, message: '请选择负载' }],
  template_types: [{ required: true, message: '请选择航线类型' }],
}

const checkedKeysData = reactive({
  data: [] as any[],
})

function switchPolyonClick () {
  enablePolyon.value = !enablePolyon.value
  store.commit('SET_MODEL_ENABLE_POLYON', enablePolyon.value)
}

function switchMeshClick () {
  enableMesh.value = !enableMesh.value
  if (enableMesh.value) {
    modelBody.generateB3dm = true
  } else {
    modelBody.generateB3dm = false
    modelBody.generateOsgb = false
    modelBody.generatePly = false
    modelBody.generateObj = false
  }
}

function switchCloudClick () {
  enableCloud.value = !enableCloud.value
  if (enableCloud.value) {
    modelBody.generatePnts = true
  } else {
    modelBody.generatePnts = false
    modelBody.generateLas = false
    modelBody.generatePointPly = false
  }
}

function jobTypeChange () {
  if (modelBody.jobType === 1) {
    modelBody.generateB3dm = true
    modelBody.generateObj = false
    modelBody.generatePly = false
    modelBody.generateOsgb = false
    modelBody.generatePnts = false
    modelBody.generateLas = false
    modelBody.generatePnts = false
  } else {
    modelBody.generateB3dm = false
    modelBody.generateObj = false
    modelBody.generatePly = false
    modelBody.generateOsgb = false
    modelBody.generatePnts = false
    modelBody.generateLas = false
    modelBody.generatePnts = false
  }
}

function locationFolder (key: string) {
  const index = folderData.data.findIndex(item => item.file_id === key)
  if (index > -1) {
    const pid = folderData.data[index].id
    if (pid) {
      getMediaFiles(workspaceId, {
        page: 1,
        page_size: 1,
        total: 1
      },
      { category: 'media', parent_id: pid }).then(res => {
        if (res.code !== 0) {
          return
        }
        const latitude = res.data.list[0].longitude
        const longitude = res.data.list[0].latitude
        if (latitude && longitude) {
          const point = {
            longitude: longitude,
            latitude: latitude,
            height: 2000
          }
          useMapToolHook.flyToPoint(point)
        }
      })
    }
  }
}

function removeFolder (key: string) {
  const index = checkedKeysData.data.findIndex(item => item.key === key)
  if (index === -1) {
    return
  }
  checkedKeysData.data.splice(index, 1)
  const checkedData = [] as any[]
  checkedKeysData.data.forEach(item => {
    checkedData.push(item.key)
  })
  store.commit('SET_FOLDER_TREE_REMOVE_DATA', checkedData)
  store.commit('SET_FOLDER_TREE_SELECTED_DATA', checkedKeysData.data)
}

function onSubmit () {
  valueRef.value.validate().then(() => {
    if (checkedKeysData.data.length === 0) {
      message.error('请选择照片')
      return
    }
    modelBody.folderUUIDs = []
    checkedKeysData.data.forEach(item => {
      modelBody.folderUUIDs.push(item.key)
    })
    if (modelBody.jobType === 0) {
      modelBody.generateB3dm = false
      modelBody.generateObj = false
      modelBody.generatePly = false
      modelBody.generateOsgb = false
      modelBody.generatePnts = false
      modelBody.generateLas = false
      modelBody.generatePnts = false
    }
    modelBody.outputPointCloud = enableCloud.value
    createJob(workspaceId, modelBody).then(res => {
      if (res.code !== 0) {
        return
      }
      message.success('新建模型重建成功!')
      useGMapCoverHook.removeFolderFromMap()
      store.commit('SET_MODEL_ENABLE_POLYON_OK', true)
      router.push('/' + ERouterName.MODEL)
    })
  }).catch((e: any) => {
    console.log('validate err', e)
  })
}

// function getJobs () {
//   getJobList(workspaceId).then(res => {
//     if (res.code !== 0) {
//       return
//     }
//     console.log('getJobList===', res)
//   })
// }

async function getFolderFiles (parent_id: string) {
  getMediaFiles(workspaceId, {
    page: 1,
    page_size: 1000,
    total: 1000
  },
  { category: 'media', parent_id: parent_id }).then(res => {
    if (res.code !== 0) {
      return
    }
    if (parent_id === '-1') {
      folderData.data = res.data.list
    }
  })
}

function getChildFolderFiles (list: any[] = []) {
  useGMapCoverHook.removeFolderFromMap()
  folderData.dataChild = []
  list.forEach(item => {
    const index = folderData.data.findIndex(key => key.file_id === item.key)
    if (index > -1) {
      const pid = folderData.data[index].id
      if (pid) {
        getMediaFiles(workspaceId, {
          page: 1,
          page_size: 1000,
          total: 1000
        },
        { category: 'media', parent_id: pid }).then(res => {
          if (res.code !== 0) {
            return
          }
          res.data.list.forEach((element: any) => {
            folderData.dataChild.push(element)
            const filePath = config.imageURL + config.imagePreviewParam + element.object_key
            useGMapCoverHook.init2DPinFolder(
              element.file_id,
              filePath,
              [Number(element.latitude), Number(element.longitude), Number(element.absolute_altitude)],
              'rectangle'
            )
          })
        })
      }
    }
  })
}

function closeModel () {
  root.$router.push('/' + ERouterName.MODEL)
}

function closePanel () {
  drawerVisible.value = false
  routeName.value = ''
}

function selectRoute () {
  drawerVisible.value = true
  routeName.value = 'selectModel'
}

watch(() => store.state.folderTreeSelectedData, data => {
  if (!data) {
    return
  }
  const newData = data.filter((item: any) => item.key !== 'all')
  getChildFolderFiles(newData)
  checkedKeysData.data = newData
})

watch(() => store.state.setModelEnablePolyonData, data => {
  modelBody.polygonPoints = []
  data.forEach((element: any) => {
    modelBody.polygonPoints.push([element[0], element[1]])
  })
},
{
  deep: true
})

onMounted(() => {
  modelBody.jobName = '模型重建-' + new Date().toLocaleDateString().replace(/\//g, '-') + '-' + new Date().toLocaleTimeString()
  store.commit('SET_FOLDER_TREE_SELECTED_DATA', [])
  // getJobs()
  getFolderFiles('-1')
})

onUnmounted(() => {
  useGMapCoverHook.removeFolderFromMap()
})
</script>

<style lang="scss" scoped>
:global(.ant-btn-primary:disabled){
  background-color: #1677ff;
  color: #fff;
}
.create-plan-wrapper {
  background-color: #232323;
  color: fff;
  padding-bottom: 0;
  height: 90vh;
  display: flex;
  flex-direction: column;
  width: 285px;

  .header {
    height: 52px;
    border-bottom: 1px solid #4f4f4f;
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .content {
    height: calc(100% - 54px);
    overflow-y: auto;
    form {
      margin: 10px;
    }

    form label, input, .ant-input, .ant-calendar-range-picker-separator,
    .ant-input:hover, .ant-time-picker .anticon, .ant-calendar-picker .anticon {
      background-color: #232323;
      color: #fff;
    }

    .ant-input-suffix {
      color: #fff;
    }

    .plan-timer-form-item {
      .ant-radio-button-wrapper{
        background-color: #232323;
        color: #fff;
        width: 80%;
        text-align: center;
        &.ant-radio-button-wrapper-checked{
          background-color: #1890ff;
        }
      }
    }
    .content-img {
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 32px;
      margin: 0 0 15px 0;
      .image {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 32px;
        .image-count {
          margin-right: 8px;
          font-size: 12px;
          line-height: 32px;
          color: hsla(0, 0%, 100%, .45);
        }
        .select-image {
          color: #2d8cf0;
          cursor: pointer;
        }
      }
    }
    .content-folder {
      display: flex;
      align-items: center;
      padding: 8px;
      height: 32px;
      background-color: #3c3c3c;
      color: #fff;
      position: relative;
      margin-bottom: 8px;
      cursor: default;
      border-radius: 2px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid #3c3c3c;
      .content-folder-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: inline-block;
      }
    }
  }

  .footer {
    display: flex;
    padding:10px 0;

    button {
      width: 45%;
      color: #fff ;
      border: 0;
    }
  }
}

.wayline-panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 70px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}
</style>
