import { GeojsonCoordinate } from '../utils/genjson'
import { getRoot } from '/@/root'

export function useMapTool () {
  const root = getRoot()
  const map = root.$map
  const AMap = root.$aMap

  function panTo (coordinate: GeojsonCoordinate) {
    map.panTo(coordinate, 100)
    map.setZoom(18, false, 100)
  }

  // 获取Camera中心点坐标
  function getCenterPosition () {
    const result = root.$viewer.camera.pickEllipsoid(new root.$Cesium.Cartesian2(root.$viewer.canvas.clientWidth / 2, root.$viewer.canvas.clientHeight / 2))
    const curPos = root.$Cesium.Ellipsoid.WGS84.cartesianToCartographic(result)
    const lon = curPos.longitude * 180 / Math.PI
    const lat = curPos.latitude * 180 / Math.PI
    const height = getHeight()
    return {
      longitude: lon,
      latitude: lat,
      height: height
    }
  }

  // 获取Camera高度
  function getHeight () {
    return root.$viewer ? root.$viewer.scene.globe.ellipsoid.cartesianToCartographic(root.$viewer.camera.position).height : 0
  }

  function flyToPoint (point: any) {
    root.$viewer.camera.flyTo({
      destination: root.$Cesium.Cartesian3.fromDegrees(point.longitude, point.latitude, point.height),
      duration: 1,
      orientation: {
        heading: root.$Cesium.Math.toRadians(0.0),
        pitch: root.$Cesium.Math.toRadians(-90.0),
        roll: root.$Cesium.Math.toRadians(0.0),
      }
    })
  }

  // 经纬度转墨卡托
  function lnglatToMercator (lnglat: any) {
    const mercator = {} as any
    const x = lnglat.lng * 20037508.34 / 180
    let y = Math.log(Math.tan((90 + lnglat.lat) * Math.PI / 360)) / (Math.PI / 180)
    y = y * 20037508.34 / 180
    mercator.x = x
    mercator.y = y
    return mercator
  }

  // 墨卡托转经纬度
  function mercatorTolnglat (mercator: any) {
    const lnglat = { lng: 0, lat: 0 }
    const x = mercator.x / 20037508.34 * 180
    let y = mercator.y / 20037508.34 * 180
    y = 180 / Math.PI * (2 * Math.atan(Math.exp(y * Math.PI / 180)) - Math.PI / 2)
    lnglat.lng = x
    lnglat.lat = y
    return lnglat
  }

  // 计算两点之间距离
  function getDistance (point1: any, point2: any, isLnglat: any) {
    let p1
    let p2
    if (isLnglat) {
      p1 = root.$Cesium.Cartesian3.fromDegrees(point1.lng, point1.lat, point1.height)
      p2 = root.$Cesium.Cartesian3.fromDegrees(point2.lng, point2.lat, point2.height)
    } else {
      p1 = new root.$Cesium.Cartesian3(point1.x, point1.y, point1.z)
      p2 = new root.$Cesium.Cartesian3(point2.x, point2.y, point2.z)
    }
    const distance = root.$Cesium.Cartesian3.distance(p1, p2)
    return distance
  }

  function getDistanceOfLine (points: any[], isLnglat: any) {
    let distance = 0
    for (let index = 0; index < points.length - 1; index++) {
      const point1 = points[index]
      const point2 = points[index + 1]
      distance = distance + getDistance(point1, point2, isLnglat)
    }
    return distance
  }

  // 计算圆形坐标点
  function getCirclePoints (longitude: number, latitude: number, height: number, radius: number) {
    // 地球半径，单位为米(WGS84参考椭球)
    const earthRadius = 6371000 // 单位:米
    // 生成圆周上的点
    const numPoints = 61 // 定义圆上点的数量，更多点会逼近一个真实的圆
    const circlePoints = []
    // 计算圆周上的各个点(使用球面坐标系)
    for (let i = 0; i < numPoints; i++) {
      const angle = (i / numPoints) * 2 * Math.PI // 均匀分布的角度
      // 使用球面坐标系计算经纬度偏移
      const dx = radius * Math.cos(angle) // 计算x轴偏移
      const dy = radius * Math.sin(angle) // 计算y轴偏移
      // 根据球面坐标计算新的经纬度
      const dLon = dx / (earthRadius * Math.cos(root.$Cesium.Math.toRadians(latitude))) // 经度偏移
      const dLat = dy / earthRadius // 纬度偏移
      // 新的经纬度
      const offsetLongitude = longitude + root.$Cesium.Math.toDegrees(dLon)
      const offsetLatitude = latitude + root.$Cesium.Math.toDegrees(dLat)
      circlePoints.push([offsetLongitude, offsetLatitude, height])
    }
    return circlePoints
  }

  return {
    panTo,
    getHeight,
    getCenterPosition,
    flyToPoint,
    lnglatToMercator,
    mercatorTolnglat,
    getDistance,
    getDistanceOfLine,
    getCirclePoints,
  }
}
