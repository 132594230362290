<template>
  <div class="project-wayline-wrapper height-100" style="width: 100%;">
    <a-spin :spinning="loading" :delay="300" tip="downloading" size="large">
      <div style="height: 50px; line-height: 50px; border-bottom: 1px solid #4f4f4f; font-weight: 450;">
        <a-row>
          <a-col :span="1"></a-col>
          <a-col :span="20">照片文件夹</a-col>
        </a-row>
      </div>
      <div :style="{ height : height + 'px'}" class="scrollbar">
        <div id="data" class="height-100 uranus-scrollbar" v-if="true" @scroll="onScroll">
          <a-tree
            v-model:expandedKeys="expandedKeys"
            v-model:selectedKeys="selectedKeys"
            v-model:checkedKeys="checkedKeys"
            checkable
            @check="treeOnChecked"
            :blockNode="true"
            :showIcon="true"
            :tree-data="treeData">
            <template #icon>
              <a-image :src="folderImg" :preview="false"></a-image>
            </template>
          </a-tree>
        </div>
        <div v-else>
          <a-empty :image-style="{ height: '60px', marginTop: '60px' }" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted, watch, ref } from 'vue'
import { ELocalStorageKey } from '/@/types'
import { useMyStore } from '/@/store'
import { IPage } from '/@/api/http/type'
import type { TreeProps } from 'ant-design-vue'
import folderImg from '/@/assets/file.png'
import { getMediaFiles } from '/@/api/media_v2'

const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!
const store = useMyStore()
const height = ref()
const loading = ref(false)
const canRefresh = ref(true)
const expandedKeys = ref<string[]>(['all'])
const selectedKeys = ref<string[]>([])
const checkedKeys = ref<string[]>([])

const treeData: TreeProps['treeData'] = reactive([
  {
    title: '全部文件',
    key: 'all',
    children: [],
  },
])

const folderData = reactive({
  data: [] as any[],
  dataChild: [] as any[],
})

const pagination :IPage = {
  page: 1,
  total: -1,
  page_size: 200
}

function treeOnChecked (_checkedKeys: string[], e: any) {
  store.commit('SET_FOLDER_TREE_SELECTED_DATA', e.checkedNodes)
}

function getFolderFiles () {
  getMediaFiles(workspaceId, {
    page: pagination.page,
    page_size: pagination.page_size,
    total: pagination.total
  },
  { category: 'media', parent_id: '-1' }).then(res => {
    if (res.code !== 0) {
      return
    }
    folderData.data = res.data.list
    res.data.list.forEach((item: any) => {
      if (treeData) {
        treeData[0].children?.push({
          title: item.file_name,
          key: item.file_id,
        })
      }
    })
    store.state.folderTreeSelectedData.forEach((item: any) => {
      checkedKeys.value.push(item.key)
    })
  }).finally(() => {
    canRefresh.value = true
  })
}

function onScroll (e: any) {
  const element = e.srcElement
  if (element.scrollTop + element.clientHeight >= element.scrollHeight - 5 && Math.ceil(pagination.total / pagination.page_size) > pagination.page && canRefresh.value) {
    pagination.page++
    getFolderFiles()
  }
}

watch(() => store.state.folderTreeRemovedData, data => {
  checkedKeys.value = data
})

onMounted(() => {
  const parent = document.getElementsByClassName('scrollbar').item(0)?.parentNode as HTMLDivElement
  height.value = document.body.clientHeight - parent.firstElementChild!.clientHeight - 20
  getFolderFiles()
})
</script>

<style lang="scss" scoped>
:global(.ant-tree-list) {
  background: rgb(40, 40, 40);
  color: #fff;
}

:global(.ant-tree-node-selected) {
  background: #3c3c3c !important;
}

:global(.ant-tree-title) {
  margin-left: 5px;
}

.route-icon {
  color: #fff;
  font-size: 16px;
}

.wayline-panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.active{
  border: 1px solid #2b85e4;
}

.uranus-scrollbar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c5c8cc transparent;
}
</style>
