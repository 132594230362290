import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store, StoreOptions, useStore } from 'vuex'
import { EDeviceTypeName } from '../types'
import { Device, OSDRecordVisible, OSDZeroneVisible, DeviceHms, DeviceOsd, DeviceStatus, DockOsd, GatewayOsd, OSDVisible, MediaAiResult, MouseDownInfo, GbDevice, OSDGBVisible, Cameras_item, ZeroneDevice } from '../types/device'
import { getLayers } from '/@/api/layer'
import { LayerType } from '/@/types/mapLayer'
import { WaylineFile } from '/@/types/wayline'
import { DevicesCmdExecuteInfo } from '/@/types/device-cmd'

const initStateFunc = () => ({
  Layers: [],
  layerBaseInfo: {} as {
    [key:string]:string
  },
  drawVisible: false,
  livestreamOthersVisible: false,
  livestreamAgoraVisible: false,
  cameraScreenSplitEnable: false,
  setModelEnablePolyon: false,
  setModelEnablePolyonOK: false,
  setDrawTargetPoint: false,
  setModelEnablePolyonData: [

  ] as any,
  // coverList: [
  // ] as any,
  coverMap: {} as {
    [key: string]: any[]
  },
  coverList: [

  ] as any,
  waylineList: [

  ] as any,
  coverFolderMap: [

  ] as any,
  waylinePointList: [

  ] as any,
  waylineRectanglePoint: {

  } as any,
  waylinePointListEditer: [

  ] as any,
  folderTreeSelectedData: [

  ] as any,
  folderTreeRemovedData: [],
  wsEvent: {
    mapElementCreat: {},
    mapElementUpdate: {},
    mapElementDelete: {}
  },
  deviceStatusEvent: {
    deviceOnline: {} as DeviceStatus,
    deviceOffline: {}
  },
  markerInfo: {
    coverMap: {} as {
      [sn: string]: any
    },
    pathMap: {} as {
      [sn: string]: any[]
    }
  },
  markerMapInfo: {
    coverMap: {} as {
      [sn: string]: any
    },
    mapData: [] as MediaAiResult[],
    mapNoneData: [] as MediaAiResult[],
  },
  mapMouseDownInfo: {
    longitude: 0,
    latitude: 0
  } as MouseDownInfo,
  deviceState: {
    // remote controller, dock
    gatewayInfo: {} as {
      [sn: string]: GatewayOsd
    },
    // drone
    deviceInfo: {} as {
      [sn: string]: DeviceOsd
    },
    dockInfo: {} as {
      [sn: string]: DockOsd
    },
    // gb28181
    gbInfo: {} as {
      [sn: string]: GbDevice
    },
    zoneInfo: {} as {
      [sn: string]: ZeroneDevice
    },
    currentSn: '',
    currentType: -1
  },
  deviceInspectInfo: {
    create_time: 0,
    job_id: '',
    lon: 0,
    lat: 0,
    path: '',
    result: '',
    sn: '',
    type: 0,
    wayline_id: ''
  },
  osdVisible: { // osd 显示设备相关信息
    sn: '',
    callsign: '',
    model: '',
    visible: false,
    gateway_sn: '',
    gateway_callsign: '',
    is_dock: false,
    is_setCenter: false,
    payloads: null,
    cameras_list: [] as Cameras_item[]
  } as OSDVisible,
  osdGBVisible: { // osd 显示设备相关信息
    deviceId: '',
    name: '',
    model: '',
    visible: false,
    channels: null
  } as OSDGBVisible,
  osdZONEVisible: { // osd 显示设备相关信息
    deviceId: '',
    name: '',
    model: '',
    visible: false,
    channels: null
  } as OSDZeroneVisible,
  waylineInfo: {

  } as WaylineFile,
  osdRecordVisible: {

  } as OSDRecordVisible,
  dockInfo: {

  } as Device,
  hmsInfo: {} as {
    [sn: string]: DeviceHms[]
  },
  // 机场指令执行状态信息
  devicesCmdExecuteInfo: {
  } as DevicesCmdExecuteInfo,
  mqttState: null as any, // mqtt 实例
  clientId: '', // mqtt 连接 唯一客户端id
  currentTaskDockSn: '', // 计划库当前选中的机场dock_sn
  currentMediaDockSn: '', // 计划库当前选中的机场dock_sn
  currentMediaAiDockSn: '', // 计划库当前选中的机场dock_sn
  currentInspectDockSn: '', // 计划库当前选中的机场dock_sn
  currentMediaLive: {} as any, // 当前选中的直播相机信息
  isVirtualCockpitMode: false, // 启用虚拟座舱模式
  currentCenterPoint: {} as any, // 当前中心点位置
  appInfo: {} as any, // 平台信息
  mapMarkerInfo: { // 地图标记点信息
    layerName: '',
    layerId: '',
    longitude: 0,
    latitude: 0,
    currentType: '',
    color: ''
  },
  mapMarkerLayer: {} as any, // 地图标记点图层
  coverMarkerInfo: [] as any[], // 机库、飞机标记
  modifiedWayline: false, // 航线已修改
  currentWaylineType: '', // 航线类型
  isEditWayline: false, // 航线编辑
  currentWaylineBody: {} as any, // 航线信息
  kmlBody: {} as any, // 航线配置信息
  currentSelectedPoint: -1, // 当前选择航点
  currentSelectedPointInfo: {} as any, // 当前选择航点信息
  currentSelectedAction: '', // 当前选择动作
  isDelAction: false, // 删除航点/算法
  algorithmData: {} as any, // 算法列表
  drawPointInfo: {} as any, // 绘制点信息
  deviceCameraList: [] as any[], // 设备相机列表
  liveUrlList: [] as any[], // 直播地址列表
  currentSelectMedia: {} as any, // 直播列表当前选中媒体
})

export type RootStateType = ReturnType<typeof initStateFunc>

const getters: GetterTree<RootStateType, RootStateType> = {
}
const mutations: MutationTree<RootStateType> = {
  SET_LAYER_INFO (state, info) {
    state.Layers = info
  },
  SET_DEVICE_INFO (state, info) {
    state.deviceState.deviceInfo[info.sn] = info.host
    state.deviceState.currentSn = info.sn
    state.deviceState.currentType = EDeviceTypeName.Aircraft
  },
  SET_INSPECT_INFO (state, info) {
    state.deviceInspectInfo = info
  },
  SET_GB_INFO (state, info) {
    state.deviceState.gbInfo[info.sn] = info.host
    state.deviceState.currentSn = info.sn
    state.deviceState.currentType = EDeviceTypeName.Gb28281
  },
  SET_ZONE_INFO (state, info) {
    state.deviceState.zoneInfo[info.sn] = info.host
    state.deviceState.currentSn = info.sn
    state.deviceState.currentType = EDeviceTypeName.Zerone
  },
  SET_GATEWAY_INFO (state, info) {
    state.deviceState.gatewayInfo[info.sn] = info.host
    state.deviceState.currentSn = info.sn
    state.deviceState.currentType = EDeviceTypeName.Gateway
  },
  SET_DOCK_INFO (state, info) {
    if (Object.keys(info.host).length === 0) {
      return
    }
    if (!state.deviceState.dockInfo[info.sn]) {
      state.deviceState.dockInfo[info.sn] = { } as DockOsd
    }
    state.deviceState.currentSn = info.sn
    state.deviceState.currentType = EDeviceTypeName.Dock
    const dock = state.deviceState.dockInfo[info.sn]
    if (info.host.mode_code !== undefined) {
      dock.basic_osd = info.host
      return
    }
    // if (info.host.sdr) {
    if (info.host.wireless_link) {
      dock.link_osd = info.host
      return
    }
    if (info.host.job_number !== undefined) {
      dock.work_osd = info.host
    }
  },
  SET_DRAW_VISIBLE_INFO (state, bool) {
    state.drawVisible = bool
  },
  SET_LIVESTREAM_OTHERS_VISIBLE (state, bool) {
    state.livestreamOthersVisible = bool
  },
  SET_LIVESTREAM_AGORA_VISIBLE (state, bool) {
    state.livestreamAgoraVisible = bool
  },
  SET_MAP_ELEMENT_CREATE (state, info) {
    state.wsEvent.mapElementCreat = info
  },
  SET_MAP_ELEMENT_UPDATE (state, info) {
    state.wsEvent.mapElementUpdate = info
  },
  SET_MAP_ELEMENT_DELETE (state, info) {
    state.wsEvent.mapElementDelete = info
  },
  SET_DEVICE_ONLINE (state, info) {
    state.deviceStatusEvent.deviceOnline = info
  },
  SET_DEVICE_OFFLINE (state, info) {
    state.deviceStatusEvent.deviceOffline = info
    delete state.deviceState.gatewayInfo[info.sn]
    delete state.deviceState.deviceInfo[info.sn]
    delete state.deviceState.dockInfo[info.sn]
    delete state.hmsInfo[info.sn]
    // delete state.markerInfo.coverMap[info.sn]
    // delete state.markerInfo.pathMap[info.sn]
  },
  SET_OSD_VISIBLE_INFO (state, info) {
    state.osdVisible = info
  },
  SET_GB_OSD_VISIBLE_INFO (state, info) {
    state.osdGBVisible = info
  },
  SET_ZONE_OSD_VISIBLE_INFO (state, info) {
    state.osdZONEVisible = info
  },
  SET_SELECT_WAYLINE_INFO (state, info) {
    state.waylineInfo = info
  },
  SET_SELECT_DOCK_INFO (state, info) {
    state.dockInfo = info
  },
  SET_DEVICE_HMS_INFO (state, info) {
    const hmsList: Array<DeviceHms> = state.hmsInfo[info.sn]
    state.hmsInfo[info.sn] = info.host.concat(hmsList ?? [])
  },
  SET_DEVICES_CMD_EXECUTE_INFO (state, info) { // 保存设备指令ws消息推送
    if (!info.sn) {
      return
    }
    if (state.devicesCmdExecuteInfo[info.sn]) {
      const index = state.devicesCmdExecuteInfo[info.sn].findIndex(cmdExecuteInfo => cmdExecuteInfo.biz_code === info.biz_code)
      if (index >= 0) {
        // 丢弃前面的消息
        if (state.devicesCmdExecuteInfo[info.sn][index].timestamp > info.timestamp) {
          return
        }
        state.devicesCmdExecuteInfo[info.sn][index] = info
      } else {
        state.devicesCmdExecuteInfo[info.sn].push(info)
      }
    } else {
      state.devicesCmdExecuteInfo[info.sn] = [info]
    }
  },
  SET_MQTT_STATE (state, mqttState) {
    state.mqttState = mqttState
  },
  SET_CLIENT_ID (state, clientId) {
    state.clientId = clientId
  },
  SET_TASK_DOCK_SN (state, deviceSn) {
    state.currentTaskDockSn = deviceSn
  },
  SET_MEDIA_DOCK_SN (state, deviceSn) {
    state.currentMediaDockSn = deviceSn
  },
  SET_MEDIA_LIVE (state, data) {
    state.currentMediaLive = data
  },
  SET_DEVICE_CAMERA_LIST (state, data) {
    state.deviceCameraList = data
  },
  SET_MEDIA_AI_DOCK_SN (state, deviceSn) {
    state.currentMediaAiDockSn = deviceSn
  },
  SET_INSPECT_DOCK_SN (state, deviceSn) {
    state.currentInspectDockSn = deviceSn
  },
  SET_MARKER_MAP_INFO (state, data) {
    state.markerMapInfo.mapData = data
  },
  SET_MARKER_MAP_INFO_NONE (state, data) {
    state.markerMapInfo.mapNoneData = data
  },
  SET_MAP_MOUSE_DOWN_INFO (state, data) {
    state.mapMouseDownInfo.longitude = data.longitude
    state.mapMouseDownInfo.latitude = data.latitude
  },
  SET_WAYLINEPOINT (state, data) {
    state.waylinePointList = data
  },
  SET_WAYLINERECTANGLEPOINT (state, data) {
    state.waylineRectanglePoint = data
  },
  SET_WAYLINEPOINTEDITER (state, data) {
    state.waylinePointListEditer = data
  },
  SET_RECORDINFO (state, data) {
    state.osdRecordVisible = data
  },
  SET_CAMERA_SCREEN_SPLIT_ENABLE (state, bool) {
    state.cameraScreenSplitEnable = bool
  },
  SET_FOLDER_TREE_SELECTED_DATA (state, data) {
    state.folderTreeSelectedData = data
  },
  SET_FOLDER_TREE_REMOVE_DATA (state, data) {
    state.folderTreeRemovedData = data
  },
  SET_MODEL_ENABLE_POLYON (state, bool) {
    state.setModelEnablePolyon = bool
  },
  SET_MODEL_ENABLE_POLYON_OK (state, bool) {
    state.setModelEnablePolyonOK = bool
  },
  SET_DRAW_TARGET_POINT (state, bool) {
    state.setDrawTargetPoint = bool
  },
  SET_MODEL_ENABLE_POLYON_DATA (state, data) {
    state.setModelEnablePolyonData = data
  },
  SET_IS_VIRTUAL_COCKPIT_MODEL (state, bool) {
    state.isVirtualCockpitMode = bool
  },
  SET_CUR_CENTER_POINT (state, data) {
    state.currentCenterPoint = data
  },
  SET_APP_INFO (state, data) {
    state.appInfo = data
  },
  SET_MARKERINFO_COVERMAP (state, info) {
    state.markerInfo.coverMap = info
  },
  SET_COVER_MAP (state, info) {
    state.coverMap = info
  },
  SET_MAP_MARKERINFO (state, info) {
    state.mapMarkerInfo = info
  },
  SET_MAP_MARKERLAYER (state, info) {
    state.mapMarkerLayer = info
  },
  SET_COVER_MARKER_INFO (state, info) {
    state.coverMarkerInfo = info
  },
  SET_MODIFIED_WAYLINE (state, bool) {
    state.modifiedWayline = bool
  },
  SET_CUR_WAYLINE_TYPE (state, data) {
    state.currentWaylineType = data
  },
  SET_IS_EDIT_WAYLINE (state, bool) {
    state.isEditWayline = bool
  },
  SET_CUR_WAYLINE_BODY (state, data) {
    state.currentWaylineBody = data
  },
  SET_KML_BODY (state, data) {
    state.kmlBody = data
  },
  SET_CUR_SEL_POINT (state, data) {
    state.currentSelectedPoint = data
  },
  SET_CUR_SEL_POINT_INFO (state, data) {
    state.currentSelectedPointInfo = data
  },
  SET_CUR_SEL_ACTION (state, data) {
    state.currentSelectedAction = data
  },
  SET_IS_DEL_ACTION (state, bool) {
    state.isDelAction = bool
    if (bool === true) {
      state.modifiedWayline = true
    }
  },
  SET_ALGORITHM_DATA (state, data) {
    state.algorithmData = data
  },
  SET_DRAWPOINT_INFO (state, data) {
    state.drawPointInfo = data
  },
  SET_LIVE_URL (state, data) {
    state.liveUrlList = data
  },
  SET_CUR_SEL_MEDIA (state, data) {
    state.currentSelectMedia = data
  },
}

const actions: ActionTree<RootStateType, RootStateType> = {
  async getAllElement ({ commit }) {
    const result = await getLayers({
      groupId: '',
      isDistributed: true
    })
    commit('SET_LAYER_INFO', result.data?.list)
  },
  updateElement ({ state }, content: {type: 'is_check' | 'is_select', id: string, bool:boolean}) {
    const key = content.id.replaceAll('resource__', '')
    const type = content.type
    const layers = state.Layers
    const layer = layers.find(item => item.id === key)
    if (layer) {
      layer[type] = content.bool
    }
  },
  setLayerInfo ({ state }, layers) {
    // const layers = state.Layers
    const obj:{
      [key:string]:string
    } = {}
    layers.forEach((layer: { type: LayerType; id: string }) => {
      if (layer.type === LayerType.Default) {
        obj.default = layer.id
      } else {
        if (layer.type === LayerType.Share) {
          obj.share = layer.id
        }
      }
    })
    state.layerBaseInfo = obj
  },
  getLayerInfo ({ state }, id:string) {
    return state.layerBaseInfo[id]
  }
}

const storeOptions: StoreOptions<RootStateType> = {
  state: initStateFunc,
  getters,
  mutations,
  actions
}

const rootStore = createStore(storeOptions)

export default rootStore

export const storeKey: InjectionKey<Store<RootStateType>> = Symbol('')

type AllStateStoreTypes = RootStateType & {
  // moduleName: moduleType
}

export function useMyStore<T = AllStateStoreTypes> () {
  return useStore<T>(storeKey)
}
